.customInput {
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
    border: 1px solid #2A4DE3;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.desc {
    font-size: 10px;
    color: grey;
    margin: 0;
    margin-left: 16px;
    cursor: pointer;
}

.icon {
    width: 100%;
    height: 100%;
    color: #2A4DE3;


}

.label {
    position: relative;
    margin: 0;
    display: flex;

}

.input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -100;
    width: 0;
    height: 0;
    opacity: 0;
}