// Variable overrides
$sidebar-width: 200px;
$body-bg: #f8f8f8;
$body-color: #000;

$theme-colors: (
  white: #fff,
  black: #000,

  primary: #2A4DE3,
  secondary: #FF9453,

  // primary: #392F2C,
  // secondary: #665D5B,

  // primary: #909C9C,
  // secondary: #ABB4B4,

  // primary: #2A4DE3,
  // secondary: #00B8D2,



  danger: #d10032,
  // danger: #ba1922,
  success: #19ba41,
  custom-color: #900,
  // light-red: #e3bec0,
  light-red: #FF6C0C,
  grey: #d5d7d8,
  light-grey: #f4f4f4,
  dark-grey: #464646,
);
