.footer {
    color: rgb(37, 37, 37);
    a {
      color: gray;
    }
    p {
        margin: 0;
        padding: 0;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    li {
        font-size: 12px;
        color: gray;
    }
    .title {
        font-size: 1.4rem;
        font-weight: bold;

        margin-bottom: 20px;
    }
    .wrapper {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        align-items: center;
        border-top: 1px solid lightgray;
        padding: 2rem 3.125rem;
        flex-wrap: wrap;

    }

    .fbIcon {
        font-size: 2rem;
        color: #1976D2;
    }

    .linkedInIcon {
        font-size: 2rem;
        color: #0077B7;
    }

    .link {
        cursor: pointer;
    }

    .copyRight {
        font-weight: bold;
    }

    .rules {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid lightgray;
        padding: 2rem 3.125rem;
        flex-wrap: wrap;
        gap: 1rem;
        ul {
            display: flex;
            gap: 20px;
        }
        a {
            color: rgb(170, 170, 170)
        }
    }
}


