.container {
    max-width: 1200px;
    margin: auto;
    min-height: calc(100vh - 278px);
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    margin: 0 20px;
}

h1 {
    font-size: 44px;
    font-weight: bold;
}

h2 {
    font-size: 22px;
    margin-bottom: 11px;
}

h3 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
}

.card {
    background-color: white;
    border-radius: 20px;
    padding: 40px;
    display: flex;
    gap: 100px;
    margin: 20px;
}

.wrapper {
    max-width: 400px;
    margin: auto;
    text-align: center;
}

.CheckCircleIcon {
    color: #00B8D2;
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
}

.step1 {
    display: flex;
    gap: 2rem;
}